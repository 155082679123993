/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    joystick: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M10 2a2 2 0 01-1.5 1.937v5.087c.863.083 1.5.377 1.5.726 0 .414-.895.75-2 .75s-2-.336-2-.75c0-.35.637-.643 1.5-.726V3.937A2 2 0 1110 2"/><path pid="1" d="M0 9.665v1.717a1 1 0 00.553.894l6.553 3.277a2 2 0 001.788 0l6.553-3.277a1 1 0 00.553-.894V9.665c0-.1-.06-.19-.152-.23L9.5 6.715v.993l5.227 2.178a.125.125 0 01.001.23l-5.94 2.546a2 2 0 01-1.576 0l-5.94-2.546a.125.125 0 01.001-.23L6.5 7.708l-.013-.988L.152 9.435a.25.25 0 00-.152.23"/>',
    },
});
